// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
const baseUrl = 'https://sso.qualisdigital.co';
export const environment = {
  production: false,
  breakerToken: `${baseUrl}/auth/login`,
  verifyOtp: `${baseUrl}/auth/verifyOtp`,
  resendCode: `${baseUrl}/auth/resendCode`,
  introspectionEndpoint: `${baseUrl}/auth//token/introspect`,
  singleSms: 'https://sms-api.qualisdigital.co//sms/message/send',
  bulkSmsUpload: `${baseUrl}//user/bulkupload`,
  bulkSmsHistory: `${baseUrl}/sms/bulk-sms-history`,
  smsHistory: `${baseUrl}/sms/sms-history`,
  smsStats: `${baseUrl}/sms/stats`,
  smsBulkFileExtracts: `${baseUrl}/sms/fetch-extracts`,
  smsBulkFileCounts: `${baseUrl}/sms/fetch-extracts-count`,
  resetPassword: `${baseUrl}/auth/reset-password`,
  validateResetCode: `${baseUrl}/auth/validate-token`,
  createPassword: `${baseUrl}/auth/create-password`,
  fetchAllCollections: `${baseUrl}/customer/payments`,
  fetchCollectionStats: `${baseUrl}/customer/payment-stats`,
  fetchAllCustomers: `${baseUrl}/customer/fetch-all-users`,
  fetchSingleCustomer: `${baseUrl}/customer`,
  searchAllCustomers: `${baseUrl}/customer/search`,
  customerSync: `${baseUrl}/customer/profile-sync`,
  loanSync: `${baseUrl}/customer/loan-sync`,
  cardHistory: `${baseUrl}/card/history`,
  customersWithCard: `${baseUrl}/card/fetch-all-customers`,
  fetchSinglePayment: `${baseUrl}/customer/fetch-payment`,
  dashboardPaymentStats: `${baseUrl}/customer/payment/stats`,
  fetchPaymentHistory: `${baseUrl}/customer/fetch-payments`,
  sendLinkForAddCard: `${baseUrl}/card/link`,
  cardDirectDebit: `${baseUrl}/customer/loan/card/pay`,
  bulkCardDeduct: `${baseUrl}/card/bulk`,
  fetchData: 'https://api.jsonbin.io/v3/b/64d6e6818e4aa6225eceb054',
  fakeData: 'https://jsonplaceholder.typicode.com/albums',
  testing: 'https://enhpzwr4lk1dc.x.pipedream.net/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
