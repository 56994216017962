import { Component, OnInit } from '@angular/core';
import { LoadingService } from './services/loading.service';
import { ThemeService } from './services/theme-service.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    public loadingService: LoadingService,
    private themeService: ThemeService
  ) {}
  title = 'admin-portal';

  ngOnInit(): void {
    this.themeService.theme$.subscribe((theme) => {
      document.body.classList.remove('light-theme', 'dark-theme');
      document.body.classList.add(`${theme}-theme`);
    });
  }
}
