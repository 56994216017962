import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { LoginAuthService } from '../services/loginAuth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private loginAuthService: LoginAuthService,
    private route: Router
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    // Your authentication logic goes here
    if (this.loginAuthService.isLoggedIn()) {
      // Return true if the route can be activated, false otherwise
      return true; // For simplicity, always allowing activation in this example
    } else {
      this.route.navigate(['login']);
      return false;
    }
  }
}
