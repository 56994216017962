import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AuthCredentials } from '../models/loginAuth.interface';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoginAuthService {
  constructor(private http: HttpClient) {}

  proceedLogin(credentials: AuthCredentials) {
    const breakerToken = environment.breakerToken;

    // Construct the URL with query parameters
    const url = `${breakerToken}?username=${credentials.username}&password=${credentials.password}&channel=${credentials.channel}`;

    return this.http.get(url);
  }
  resendCode() {
    const sessionID = localStorage.getItem('sessionID');
    const resendCodeUrl = environment.resendCode;
    const completeUrl = `${resendCodeUrl}?sessionId=${sessionID}`;
    return this.http.get(completeUrl);
  }

  proceedLoginAfterVerification(otp: any) {
    const sessionID = localStorage.getItem('sessionID');

    const verifyOtp = environment.verifyOtp;
    const verifiedUrl = `${verifyOtp}?sessionId=${sessionID}&otp=${otp}`;
    return this.http.get(verifiedUrl);
  }

  isLoggedIn() {
    return localStorage.getItem('token') != null;
  }
  getToken() {
    return localStorage.getItem('token') != null
      ? localStorage.getItem('token')
      : '';
  }
}
