// auth-interceptor.service.ts

import { Injectable, Injector } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class TokenInterceptorService implements HttpInterceptor {
  constructor(private router: Router, private toastr: ToastrService) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = localStorage.getItem('token');

    if (token) {
      const authRequest = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });

      return next.handle(authRequest).pipe(
        catchError((error: HttpErrorResponse) => {
          if (error && error.status === 0) {
            // Token expired or unauthorized, perform logout or redirect to login
            this.handleUnauthorizedError();
          }
          return throwError(() => error);
        })
      );
    }

    return next.handle(request);
  }

  handleUnauthorizedError(): void {
    // Display Toastr notification
    this.toastr.warning('Session expired. Please log in again', '', {
      timeOut: 3000,
      progressBar: true,
    });

    // Redirect to the login page after a delay
    setTimeout(() => {
      // Implement your logic here, such as logging out the user or redirecting to login
      this.router.navigate(['/login']);
      localStorage.clear();
      // Set another timeout for 1 second to refresh the page
      setTimeout(() => {
        // Refresh the page
        window.location.reload();
      }, 500);
    }, 3000);
  }
}
