import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guard/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./components/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'sms-history',
    loadChildren: () =>
      import('./components/sms-history/history.module').then(
        (m) => m.HistoryModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'sms/send-single',
    loadChildren: () =>
      import('./components/single-sms/single-sms.module').then(
        (m) => m.SingleSmsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'sms/send-bulk',
    loadChildren: () =>
      import('./components/bulk-sms/bulk-sms.module').then(
        (m) => m.BulkSmsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'collections',
    loadChildren: () =>
      import('./components/collections/collections.module').then(
        (m) => m.CollectionsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'view-bulk-sms',
    loadChildren: () =>
      import('./components/view-bulk-sms/view-file.module').then(
        (m) => m.ViewFileModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'customer-management',
    loadChildren: () =>
      import(
        './components/customer-management/customer-management.module'
      ).then((m) => m.CustomerManagementModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'loan-applications',
    loadChildren: () =>
      import('./components/loan-applications/loan-applications.module').then(
        (m) => m.LoanApplicationsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'reports',
    loadChildren: () =>
      import('./components/reports/reports.module').then(
        (m) => m.ReportsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'view-loan',
    loadChildren: () =>
      import('./components/view-loan/view-loan.module').then(
        (m) => m.ViewLoanModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'view-customer-profile',
    loadChildren: () =>
      import('./components/view-customer-profile/view-profile.module').then(
        (m) => m.ViewProfileModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'view-debit-batch',
    loadChildren: () =>
      import('./components/debit-batch/debit-batch.module').then(
        (m) => m.DebitBatchModule
      ),
    canActivate: [AuthGuard],
  },

  {
    path: 'add-loan',
    loadChildren: () =>
      import('./components/add-loan/add-loan.module').then(
        (m) => m.AddLoanModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'payment-details',
    loadChildren: () =>
      import('./components/view-payment/view-payment.module').then(
        (m) => m.ViewPaymentModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'card-payment-schedules',
    loadChildren: () =>
      import('./components/card-payments-schedules/schedules.module').then(
        (m) => m.SchedulesModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'customer-cards',
    loadChildren: () =>
      import('./components/customer-cards/card-holders-list.module').then(
        (m) => m.CardHoldersListModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'card-transactions',
    loadChildren: () =>
      import('./components/card-transactions/card-transactions.module').then(
        (m) => m.CardTransactionsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'bulk-card-deductions',
    loadChildren: () =>
      import(
        './components/bulk-card-deductions/bulk-card-deductions.module'
      ).then((m) => m.BulkCardDeductionsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'requests-permission',
    loadChildren: () =>
      import('./components/requests/requests.module').then(
        (m) => m.RequestsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./components/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'reset-password',
    loadChildren: () =>
      import(
        './components/password-resets/reset-password/reset-password.module'
      ).then((m) => m.ResetPasswordModule),
  },
  {
    path: 'check-email',
    loadChildren: () =>
      import(
        './components/password-resets/check-email/check-email.module'
      ).then((m) => m.CheckEmailModule),
  },
  {
    path: 'create-password',
    loadChildren: () =>
      import(
        './components/password-resets/create-password/create-password.module'
      ).then((m) => m.CreatePasswordModule),
  },
  {
    path: '**',
    loadChildren: () =>
      import('./components/not-found/not-found.module').then(
        (m) => m.NotFoundModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
